import "./App.scss";
import { Route, Routes } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./screens/Home";
import { ThemeProvider, createTheme } from "@mui/material";
// import QuestionAndAnswers from "./screens/Chat";
import LabTabs from "./screens/Tabs";
import SignUpDialog from "./screens/Signup";
import pbResponseAILogo from "../src/assets/pbResponseAI.png";
import AllBot from "./screens/AllBotHomePage/AllBot";
import Published from "./screens/Tabs/published";
import AuthRoutes from "./components/authRoutes";
import Navbar from "./screens/Navbar";
import { useState } from "react";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";
import Steps from "./screens/Stepper/Steps";
import { checkIfLogin } from "./utils/sessionManagement";
import VoiceChat from "./screens/Stepper/VoiceChat";
import LandingPage from "./screens/LandingPage";
import YouTubeUpload from "./screens/YouTubeUpload";
import Home1 from "./screens/Home/Home";
import Research from "./screens/Research/Research";
import ResearchLanding from "./screens/Research";
import BioBot from "./screens/BioBot";
import BioBotReplica from "./screens/BioBot_replica";
import FDADraft from "./screens/FDAScreen/fdaDraft";
import Policy from "./screens/Policy/Policy";
import TermsConditions from "./screens/TermsConditions/TermsConditions";
import ScrollToTop from "./screens/ScrollToTop/ScrollToTop";
import PDFHighlighter from "./screens/BioBot/PDFHighlighter";
import DocumentGenerator from "./screens/DocumentGenerator";
import PdfUploader from "./screens/Training/pdfUploader";
import PdfPreview from "./screens/Training/PdfPreview";
import Survey from "./screens/Survey";
import ShowQR from "./screens/Survey/ShowQR";
import QaAnalytics from "./screens/QaAnalytics";
import CsvAnalytics from "./screens/CsvAnalytics";
import Assessment from "./screens/Assessment";
import Accreditation from "./screens/Accreditation";
import RegulatoryDrafting from "./screens/RegulatoryDrafting";
function App() {
  const [signInFlag, setSignFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState("0");
  const [activeCard, setActiveCard] = useState(0);

  const { loading } = useSelector((state) => state.loginDetails);

  let theme = createTheme({
    palette: {
      primary: {
        main: "#001A5C",
      },
      secondary: {
        main: "#0074FC",
      },
    },
  });

  // if (loading) {
  //   return (
  //     <div
  //       style={{
  //         height: "100vh",
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Loader />
  //     </div>
  //   );
  // }

  return (
    <div>
      <ToastContainer position="top-center" />
      <ThemeProvider theme={theme}>
        <div className="App">
          <Navbar
            setOpen={setOpen}
            setSignFlag={setSignFlag}
            tabValue={tabValue}
            setTabValue={setTabValue}
          />
          <ScrollToTop>
            <Routes>
              <Route
                path={"/"}
                element={
                  <LandingPage
                    open={open}
                    setOpen={setOpen}
                    signInFlag={signInFlag}
                  />
                }
              />

              <Route path="/signup" element={<SignUpDialog />}></Route>
              <Route
                path="/docGenerate"
                element={<DocumentGenerator />}
              ></Route>
              <Route path="/survey" element={<Survey />}></Route>
              <Route path="/youtube" element={<YouTubeUpload />}></Route>
              <Route path="/training" element={<PdfUploader />}></Route>
              <Route path="/surveyQR" element={<ShowQR />}></Route>
              <Route path="/privacy-policy" element={<Policy />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsConditions />}
              />
              <Route element={<AuthRoutes />}>
                <Route
                  path="/home"
                  element={
                    <Home1
                      activeCard={activeCard}
                      setActiveCard={setActiveCard}
                    />
                  }
                ></Route>
                <Route path="/research" element={<Research />}></Route>
                <Route
                  path={"/loginHome"}
                  element={
                    <Home
                      open={open}
                      setOpen={setOpen}
                      signInFlag={signInFlag}
                    />
                  }
                />
                <Route path="/bioBot" element={<BioBot />} />
                <Route path="/newBot" element={<BioBotReplica />} />

                <Route path="/assessment" element={<Assessment />} />
                <Route path="/accreditation" element={<Accreditation />} />
                <Route
                  path="/regulatory-drafting"
                  element={<RegulatoryDrafting />}
                />

                <Route path="/allBots" element={<AllBot />} />
                {/* <Route path="/chat/:id" element={<QuestionAndAnswers />} /> */}
                <Route
                  path="/tabs"
                  element={
                    <LabTabs tabValue={tabValue} setTabValue={setTabValue} />
                  }
                />

                <Route path="/publish" element={<Published />}></Route>
                <Route path="/steps" element={<Steps />}></Route>
                <Route path="/voiceChat" element={<VoiceChat />}></Route>
                <Route path="/fdaDraft" element={<FDADraft />}></Route>
                <Route
                  path="/pdfHighlighter"
                  element={<PDFHighlighter />}
                ></Route>

                <Route path="/qaAnalysis" element={<QaAnalytics />}></Route>
                <Route path="/csvAnalysis" element={<CsvAnalytics />}></Route>
              </Route>
            </Routes>
          </ScrollToTop>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
