import React, { useEffect, useState } from "react";
import "./history.scss";
import backArrow from "../../assets/backErrow.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "antd";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import historyIcon from "../../assets/chatHistoryIcon.svg";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { getData } from "../../utils/storageService";

const History = ({
  setChatEsIndex,
  setUpdatedResp,
  getHistoryCardsData,
  chatHistory,
  setChatID,
  setChatLoading,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [chatDropdown, setChatDropdown] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(false);

  const urlParams = new URLSearchParams(location.search);

  const config = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_BIO_BOT_TOKEN}`,
      // Access-Control-Allow-Origin: *
    },
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/chat_dropdown`, config)
      .then((response) => {
        console.log(response);
        setChatDropdown(response?.data?.dropdown_list);
        // setTheraputicArea(response?.data?.dropdown_list[0]);
        // setChatEsIndex(response?.data?.dropdown_list[0]);

        setTheraputicArea("Electricity Regulatory Commission");
        setChatEsIndex("Electricity Regulatory Commission");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleCardClick = (chatID) => {
    urlParams.set("chatID", chatID);
    setChatID(chatID);
    const bodyParameters = { emailID: getData("Email") };
    setChatLoading(true);
    axios
      .post(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/${"getmessagehistory"}?${urlParams}`,
        bodyParameters,
        config
      )
      .then(function (response) {
        setLoading(null);
        setChatLoading(false);

        response.data.messages.length > 0 &&
          setUpdatedResp(response.data.messages);
        console.log("msg history", response.data.messages);
        navigate(`${location.pathname}?${urlParams.toString()}`);
      })
      .catch(function (error) {
        console.log(error);
        setChatLoading(false);
        setLoading(null);
        toast.error(
          "Sorry, the server is currently under maintenance. Please try again later."
        );
      });
  };

  const handleNewSession = () => {
    urlParams.set("chatID", "");
    setUpdatedResp([]);
    // setOldMsgs([]);
    setChatID("");
    // navigate("/bioBot");
    navigate(`${location.pathname}?${urlParams.toString()}`);
    getHistoryCardsData(setHistoryLoading);
  };

  useEffect(() => {
    getHistoryCardsData(setHistoryLoading);

    if (urlParams.get("chatID") && urlParams.get("chatID") !== "") {
      console.log("urlParams", urlParams.get("chatID"));
      handleCardClick(urlParams.get("chatID"));
    }
  }, [urlParams.get("chatID")]);

  const [theraputicArea, setTheraputicArea] = React.useState("");

  const handleChange = (event) => {
    console.log(event.target.value);
    setTheraputicArea(event.target.value);
    setChatEsIndex(event.target.value);
  };
  return (
    <div className="biobot-history">
      <img
        className="arrow-img"
        src={backArrow}
        alt=""
        onClick={() => navigate("/home")}
      />
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel>Area of interest</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={theraputicArea}
            input={<OutlinedInput label="Theraputic Area" />}
            value={theraputicArea}
            inputProps={{ "aria-label": "Without label" }}
            onChange={handleChange}
          >
            {/* {chatDropdown.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))} */}
            <MenuItem value="Electricity Regulatory Commission">
              Electricity Regulatory Commission
            </MenuItem>
            <MenuItem value="Regulatory Comparison">
            Regulatory Comparison
            </MenuItem>
            <MenuItem value="Regulatory Comments">
            Regulatory Comments
            </MenuItem>

            {/* <MenuItem value={"Oncology"}>Oncology</MenuItem>
            <MenuItem value={"Diabetes"}>Diabetes</MenuItem>
            <MenuItem value={"FDA Labels"}>FDA Labels</MenuItem> */}
            {/* <MenuItem value={"Policy Document"}>Policy Document</MenuItem>
            <MenuItem value={"Schedule of Charge"}>Schedule of Charge</MenuItem> */}
          </Select>
        </FormControl>
      </Box>
      <h4 className="heading">Your Sessions</h4>
      <Button className="create-btn" onClick={handleNewSession}>
        <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon> &nbsp; New
        Session
      </Button>
      {historyLoading ? (
        <div className="historyLoader">
          <CircularProgress />
        </div>
      ) : (
        <>
          {console.log("historyLoading", historyLoading)}
          <div className="history-card-box">
            {chatHistory.map((chat, idx) => {
              return (
                <div
                  key={idx}
                  className="chat-card"
                  style={{
                    borderLeft:
                      urlParams.get("chatID") &&
                      Number(urlParams.get("chatID")) === chat.chatID
                        ? "5px solid blue"
                        : "5px solid white",
                  }}
                  onClick={() => handleCardClick(chat?.chatID)}
                >
                  <div className="chat-card-section-1">
                    <img src={historyIcon} alt="" />
                    <div className="chat-history-box">
                      <div className="session-text">
                        {chat?.chatTitle.split("").slice(0, 30).join("")}
                        {chat?.chatTitle.split("").length > 30 ? "..." : ""}
                      </div>
                      <div className="session">
                        {moment(chat?.start_time).format("D MMMM h:mm A")}
                      </div>
                    </div>
                  </div>
                  <div className="more">
                    {/* <img src={more} alt="" /> */}
                    <MoreVertIcon style={{ borderRadius: "50%" }} />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default History;
