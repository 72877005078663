import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import "./index.scss";
import SignUpDialog from "../Signup";
import { checkIfLogin } from "../../utils/sessionManagement";
import section2img from "../../assets/Group 2566.png";
import part3 from "../../assets/section-3-icon.svg";
import section3Img from "../../assets/section3.svg";
import biobot from "../../assets/bioBot.svg";
import draft from "../../assets/draft.svg";
import research from "../../assets/research.svg";
import build from "../../assets/buildbot.svg";
import video from "../../assets/background-video.mp4";
import { useNavigate } from "react-router-dom";

const LandingPage = ({ open, setOpen, signInFlag }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (!checkIfLogin()) {
      setOpen(true);
      return;
    }
  };

  return (
    <div className="landing-page">
      <Grid container spacing={2}>
        <div className="video-wrapper">
          <video
            preload="metadata"
            playsInline
            autoPlay
            muted
            loop
            id="landing-video"
            // poster="../../assets/homebackground.png"
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <div className="header">
            <SignUpDialog
              signInFlag={signInFlag}
              open={open}
              setOpen={setOpen}
            />
            <Box className="landing-page-1">
              <Grid item sm={7}>
                <Box className="text-container">
                  <Typography variant="" className="heading">
                    {/* Response */} Power AI{" "}
                    {/* <span style={{ color: "#001A5C" }}>AI</span> */}
                  </Typography>

                  <div className="pera-1">
                    {/* Lifesciences enterprise
                    <br /> conversational AI platform */}
                    Central Electricity Regulatory Commission
                  </div>

                  <div variant="" className="pera-2">
                    {/* Helping Pharma businesses to automate
                    <br /> experiences for customers and employees. */}
                    Helping Central Electricity Regulatory Commission to
                    automate regulatory and operational processes.
                  </div>

                  <div variant="body1" className="desc">
                    {/* ResponseAI is powered by GenerativeAI and natural language
                    processing (NLP) technology, which allows it to deliver
                    human-like personalized interactions in the preferred
                    language. The platform also assists in Intelligent Drafting,
                    Smart Research and custom bot development. */}
                    PowerAI is powered by GenerativeAI and natural language
                    processing (NLP) technology, enabling it to deliver
                    personalized and efficient interactions in the preferred
                    language. The platform supports Smart Research, and
                    Intelligent Drafting, to streamline regulatory functions and
                    enhance decision-making efficiency.
                  </div>
                  {/* <Button
                    // variant="contained"
                    className="learn-btn"
                    onClick={handleClick}
                  >
                    Learn More
                  </Button> */}
                </Box>
              </Grid>
              <Grid item sm={5}></Grid>
            </Box>
          </div>
        </div>
      </Grid>

      <Box className="landing-page-part2">
        <Box className="section-2-img" sm={6}>
          <img src={section2img} alt="" />
        </Box>

        <Box className="textcontainer-part2" sm={6}>
          <div className="section2-text1">
            Power AI: A Wealth of Capabilities for a Diverse Range of Needs
          </div>
          <div className="section2-text2">
            Power AI is powered by state-of-the-art Gen AI technology and finely
            tuned through extensive training on life sciences data, Power AI
            offers a wealth of capabilities to cater to a diverse range of
            needs.
          </div>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <div>
                <img src={biobot} className="images"></img>
                <div className="text-grid">
                  <b>Conversational Excellence: </b> Power AI excels in
                  facilitating natural, meaningful conversations. Its AI-driven
                  chat capabilities are designed to engage users seamlessly,
                  providing intelligent responses and context-aware
                  interactions. Whether it's customer support, virtual
                  assistants, or engaging discussions, Power AI sets the
                  standard for conversational excellence.
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <div>
                <img src={draft} className="images"></img>
                <div className="text-grid">
                  <b>Effortless Drafting:</b> Power AI is your drafting
                  companion, capable of generating high-quality content
                  effortlessly. From drafting responses to SOPs, it understands
                  your intent and generates contextually relevant text, saving
                  time and enhancing quality
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <div>
                <img src={research} className="images"></img>
                <div className="text-grid">
                  <b> In-Depth Research:</b> Access to knowledge has never been
                  easier. Power AI harnesses its extensive training on life
                  sciences data to assist you in conducting deep research. it
                  can provide valuable insights and assist with information
                  retrieval, analysis, and synthesis.
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={6}>
              {" "}
              <div>
                <img src={build} className="images"></img>
                <div className="text-grid">
                  <b>Custom Bot Creation: </b>Building custom chatbots tailored
                  to your specific requirements is a breeze with Power AI.
                  Harnessing its adaptability you can create intelligent bots
                  for tasks ranging from clinical research to automating
                  customer interactions
                </div>
              </div>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
      <Box className="landing-page-part3">
        <Box className="section-3-img" sm={6}>
          <img src={section3Img} alt="" />
        </Box>
        <Box className="text-container" sm={6}>
          <img src={part3} alt=""></img>
          <div className="section-3-text-1">
            Enhance customer and employee experiences using our multi-LLM
            architecture, continuously trained on billions of data for
            scalability, speed, and accuracy. Reduce costs with seamless
            multimodal automation.
          </div>
          <div className="section-3-text-3">Key Features </div>{" "}
          <ul>
            <li className="li-tags">
              <div className="section-3-text-2">
                <b>Comprehensive Information:</b> The platform provides detailed
                insights for informed decision-making.
              </div>{" "}
            </li>
            <li className="li-tags">
              <div className="section-3-text-2">
                <b> Adaptive Learning:</b> ResponseAI utilizes machine learning
                algorithms to continuously refine its knowledge base and stay
                up-to-date with the latest advancements in industry.
              </div>
            </li>
            <li className="li-tags">
              <p className="section-3-text-2">
                <b> User-Friendly Interface: </b>With its intuitive and
                easy-to-navigate layout, the platform is optimized for various
                devices and can seamlessly integrate with existing systems and
                platforms.
              </p>
            </li>
          </ul>
        </Box>
      </Box>
      <Box className="footer">
        <div className="footer-bottom">
          <p className="copyright">
            &copy; 2023 Perpetual Block AG. All rights reserved.
            {/* <span className="seperator "> &nbsp; | &nbsp; </span>{" "} */}
          </p>
          <p className="copyright-condition">
            <span
              className="policy"
              onClick={() => navigate("/terms-and-conditions")}
            >
              Terms and Conditions
            </span>{" "}
            <span className="line-spacing" style={{ color: "white " }}>
              {" "}
              &nbsp; | &nbsp;{" "}
            </span>{" "}
            <span
              className="policy"
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy Policy
            </span>
          </p>
        </div>
      </Box>
    </div>
  );
};

export default LandingPage;
