import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import "./index.scss";
import { InputAdornment, TextField } from "@mui/material";
import axios from "axios";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { toast } from "react-toastify";
import { Margin, Resolution, usePDF } from "react-to-pdf";
import backArrow from "../../assets/backErrow.svg";
import amritTemplate from "../../assets/ppt-template-amrit.png";
import partexTemplate from "../../assets/ppt-template-partex.png";
import pbTemplate from "../../assets/ppt-template-pb.png";
import HighlightModal from "../BioBot/HighlightModal";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import pdf from "../../assets/pdf.png";
import ppt from "../../assets/ppt.png";
import doc from "../../assets/doc.png";
import copy from "../../assets/copyClipboard.svg";

const RegulatoryDrafting = () => {
  const [text, setText] = useState("");
  const [searchResults, setSearchResults] = useState();
  const [output, setOutput] = useState();
  const [selectedMetadata, setSelectedMetadata] = useState([]);
  const token = process.env.REACT_APP_BIO_BOT_TOKEN;
  const [selectAll, setSelectAll] = useState(false);
  const [pdfModal, SetPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [cardLoading, setCardLoading] = useState(false);
  const [outputLoading, setOutputLoading] = useState(false);
  const [multiline, setMultiline] = useState(false);
  const [downloadDropdown, setDownloadDropdown] = useState(null);
  const [openPptModal, setOpenPptModal] = useState(false);
  const navigate = useNavigate();
  // const [theraputicArea, setTheraputicArea] = React.useState(
  //   "Electricity Regulatory Commission"
  // );

  const handleSearch = () => {
    const requestData = { query: text, esIndex: "" };
    setCardLoading(true);

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/generateCerc`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setCardLoading(false);
        setOutput(response?.data?.html_data);
      })
      .catch(function (error) {
        console.log(error);
        setCardLoading(false);
      });
  };

  const closePdfModal = () => {
    SetPdfModal(false);
  };

  const downloadPDF = () => {
    const requestBody = {
      html_content: output,
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/htmlPDFDownload`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "arraybuffer",
        }
      )
      .then(function (response) {
        console.log(response.data);
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Document.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(function (error) {
        console.log(error);
      });
    setDownloadDropdown(null);
  };

  const handleGeneratePPT = () => {
    setOpenPptModal(true);
    setDownloadDropdown(null);
  };

  const generatePPT = (template) => {
    // setOutputLoading(true);
    const selectedMetadata1 = selectedMetadata?.map((metadata) => ({
      metadata,
    }));

    const requestBody = {
      ppt_data: output,
      ppt_template: template,
    };

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/generatePPT`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        responseType: "arraybuffer",
      })
      .then(function (response) {
        // setOutputLoading(false);

        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Document.pptx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(function (error) {
        console.log(error);
      });

    setDownloadDropdown(null);
    setOpenPptModal(false);
  };

  const handleCopyToClipboard = () => {
    let regX = /(<([^>]+)>)/gi;
    navigator.clipboard.writeText(output.replace(regX, ""));
    toast.success("Copied !");
  };
  const { toPDF, targetRef } = usePDF({
    filename: "Document.pdf",
    method: "save",
    resolution: Resolution.HIGH,
    page: { margin: Margin.MEDIUM, orientation: "portrait" },
  });

  const OpenDownloadDropdown = (event) => {
    setDownloadDropdown(event.currentTarget);
  };

  const downloadFile = (content, type, filename) => {
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadPPT = () => {
    const pptContent = output;
    downloadFile(
      pptContent,
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "Document.pptx"
    );
  };

  const downloadDOCX = () => {
    const docxContent = output;
    downloadFile(
      docxContent,
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "Document.docx"
    );
    setDownloadDropdown(null);
  };
  const handleChange = (event) => {
    console.log(event.target.value);
    // setTheraputicArea(event.target.value);
    setOutput();
    setSearchResults();
    setText("");
    setSelectedMetadata([]);
    setSelectAll(false);
    setCardLoading(false);
    setOutputLoading(false);
    setPdfUrl("");
    setOpenPptModal(false);
    setDownloadDropdown(null);
    // setChatEsIndex(event.target.value);
  };

  return (
    <div className="doc-generator">
      <img
        src={backArrow}
        alt=""
        style={{
          cursor: "pointer",
          marginBottom: "10px",
          paddingTop: "3%",
          paddingRight: "3%",
          width: "80px",
        }}
        onClick={() => navigate(-1)}
      />

      <Modal open={openPptModal} onClose={() => setOpenPptModal(false)}>
        <div className="ppt-template-modal">
          <div className="modal-heading">Select template to download</div>
          <div className="card-wrap">
            <div
              className="template-card"
              onClick={() => generatePPT("Amrit_Template")}
            >
              <img src={amritTemplate} alt="" />
              Amrit Template
            </div>
            <div
              className="template-card"
              onClick={() => generatePPT("Partex_Template")}
            >
              <img src={partexTemplate} alt="" />
              Partex Template
            </div>
            <div
              className="template-card"
              onClick={() => generatePPT("PB_Template")}
            >
              <img src={pbTemplate} alt="" />
              PB Template
            </div>
            {/* {theraputicArea === "Oncology" && <div
            className="template-card"
            onClick={() => generatePPT("Guardant_Template")}
          >
            <img src={guardantTemplate} alt="" />
            Guardant Template
          </div>}
          {theraputicArea === "Diabetes" && <div
            className="template-card"
            onClick={() => generatePPT("Biocon_Template")}
          >
            <img src={bioconTemplate} alt="" />
            Biocon Template
          </div>} */}
          </div>
        </div>
      </Modal>
      <div className="doc-wrapper">
        {pdfModal && (
          <HighlightModal
            pdfUrl={pdfUrl}
            open={pdfModal}
            handleClose={closePdfModal}
          />
        )}
        <h2
          style={{
            color: "rgba(0, 26, 92, 1)",
            margin: "1%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {" "}
          Smart Document Generator
        </h2>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            className="research-searchbar"
          //   placeholder="Enter Your Query Here to search relevant reference materials
          // "
            variant="outlined"
            disabled
            multiline={true}
            value="Section 5 of the order summary for petition no. 401/TT/2024"
           defaultValue="Section 5 of the order summary for petition no. 401/TT/2024"
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "100px",
              },
            }}
            onKeyDown={(e) => {
              if (!e.shiftKey && e.key === "Enter") {
                e.preventDefault();
                handleSearch();
              } /* else if (e.shiftKey && e.key === "Enter") {
              setMultiline(true);
            } */
            }}
            onChange={(e) => setText(e?.target?.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchSharpIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    onClick={handleSearch}
                    style={{
                      borderRadius: "50px",
                      textTransform: "capitalize",
                    }}
                  >
                    {cardLoading ? (
                      <>
                        Generating... &nbsp;
                        <CircularProgress color="inherit" size={20} />{" "}
                      </>
                    ) : (
                      "Generate"
                    )}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </div>
        {/* <div style={{ display: "flex", gap: "50px", marginTop: "30px" }}>
          <Button
            variant="contained"
            // onClick={handleSearch}
            disabled
            style={{
              borderRadius: "10px",
              textTransform: "capitalize",
            }}
          >
            Section 1
          </Button>
          <Button
            variant="contained"
            // onClick={handleSearch}
            disabled
            style={{
              borderRadius: "10px",
              textTransform: "capitalize",
            }}
          >
            Section 2
          </Button>
          <Button
            variant="contained"
            // onClick={handleSearch}
            disabled
            style={{
              borderRadius: "10px",
              textTransform: "capitalize",
            }}
          >
            Section 3
          </Button>
          <Button
            variant="contained"
            // onClick={handleSearch}
            disabled
            style={{
              borderRadius: "10px",
              textTransform: "capitalize",
            }}
          >
            Section 4
          </Button>
          <Button
            variant="contained"
            onClick={handleSearch}
            style={{
              borderRadius: "10px",
              textTransform: "capitalize",
            }}
          >
            {cardLoading ? (
              <>
                Generating... &nbsp;
                <CircularProgress color="inherit" size={20} />{" "}
              </>
            ) : (
              "Section 5"
            )}
          </Button>
        </div> */}
        <br />
        {output && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className="output">Output</Typography>
              <div style={{ display: "flex" }}>
                <Button onClick={handleCopyToClipboard}>
                  <img src={copy} alt="" />
                </Button>

                <Button onClick={(e) => OpenDownloadDropdown(e)}>
                  <FileDownloadOutlinedIcon
                    style={{ color: "#0074FC", fontSize: "40px" }}
                  />
                </Button>
                <Menu
                  id="download"
                  anchorEl={downloadDropdown}
                  keepMounted
                  open={Boolean(downloadDropdown)}
                  onClose={() => setDownloadDropdown(null)}
                  sx={{ background: "#00000060" }}
                >
                  <MenuItem
                    onClick={downloadPDF}
                    className="list-item-download"
                  >
                    {" "}
                    <img src={pdf} alt="" />
                    PDF
                  </MenuItem>
                  <MenuItem
                    onClick={downloadDOCX}
                    className="list-item-download"
                  >
                    {" "}
                    <img src={doc} alt="" />
                    DOC
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <div className=" output-result">
              {/* {theraputicArea == "Protocol" ? ( */}
              <pre
                // className="pre-data"
                style={{
                  textAlign: "left",
                  padding: "1vw",
                  whiteSpace: "pre-wrap",
                }}
                ref={targetRef}
                dangerouslySetInnerHTML={{ __html: output }}
              >
                {/* {output} */}
              </pre>
              {/* // ) 
              // : (
              //   <pre
              //     className="pre-data"
              //     style={{ textAlign: "left", padding: "1vw" }}
              //     ref={targetRef}
              //     // dangerouslySetInnerHTML={{ __html: output }}
              //   >
              //     {output}
              //   </pre>
              // )} */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RegulatoryDrafting;
